<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="w-100">
		<section
			:style="styles"
			class="hero">
			<b-row
				class="main-content"
				:class="{
					'h-75': isDesktop,
				}">
				<b-col
					cols="12"
					lg="7"
					class="d-flex flex-column align-items-center justify-content-center"
					:class="{
						'text-center': isMobile,
					}">
					<div
						:class="{
							'w-100': isMobile
						}">
						<h1
							:style="isMobile ? 'font-size: 2.5rem' : ''"
							v-html="translate('member_rewards_hero_title')" />
						<p
							:style="isMobile ? 'font-size: 1.5rem' : ''"
							v-html="translate('member_rewards_hero_sub_title')" />
					</div>
				</b-col>
				<b-col
					cols="12"
					lg="5"
					class="h-100"
					:class="{
						'd-flex justify-content-center': isMobile,
					}">
					<img
						:src="requireAsset('hero/hero.png')"
						:style="isMobile ? 'width: 50%' : 'height: 100%;'"
						alt="A woman jumping">
				</b-col>
			</b-row>
			<b-row class="footer">
				<b-col
					cols="12"
					class="d-flex flex-column align-items-center justify-content-center">
					<div class="opportunity">
						<h5
							:style="isMobile ? 'font-size: 1.5rem' : ''"
							v-html="translate('member_rewards_hero_opportunity')" />
						<p v-html="translate('member_rewards_hero_description')" />
					</div>
				</b-col>
			</b-row>
		</section>
		<section class="overview">
			<div class="container">
				<b-row class="d-flex justify-content-center">
					<b-col
						cols="12"
						lg="5">
						<img
							:src="requireAsset('overview/girl-with-computer.png')"
							:style="isMobile ? 'width: 300px' : 'height: 300px;'"
							alt="A woman with a computer">
					</b-col>
					<b-col
						cols="12"
						lg="5"
						class="d-flex flex-column justify-content-center py-3">
						<h5
							:style="isMobile ? 'font-size: 1.5rem' : ''"
							v-html="translate('member_rewards_overview_1_title')" />
						<p v-html="translate('member_rewards_overview_1_description')" />
					</b-col>
				</b-row>
				<b-row class="d-flex justify-content-center">
					<b-col
						cols="12"
						lg="5"
						class="d-flex flex-column justify-content-center py-3">
						<p v-html="translate('member_rewards_overview_2_description')" />
					</b-col>
					<b-col
						cols="12"
						lg="5"
						class="d-flex justify-content-end">
						<img
							:src="requireAsset('overview/girl-on-cellphone.png')"
							:style="isMobile ? 'width: 300px' : 'height: 300px;'"
							alt="A woman with a cellphone">
					</b-col>
				</b-row>
			</div>
		</section>
		<section class="video-section">
			<b-modal
				id="member-rewards-modal-video"
				modal-class="media-modal"
				hide-footer
				centered
				size="lg">
				<div>
					<b-embed
						type="iframe"
						aspect="16by9"
						:src="currentVideoUrl"
						allow="autoplay; fullscreen"
						allowfullscreen />
				</div>
			</b-modal>
			<div class="container d-flex flex-column align-items-center">
				<h5 v-html="translate('member_rewards_videos_title')" />
				<div
					class="buttons d-flex flex-wrap justify-content-center"
					style="gap: 10px">
					<a
						class="btn btn-primary"
						:href="brochures.memberRewardsChart"
						target="_blank">
						{{ translate('member_rewards_videos_cta_1') }}
					</a>
					<a
						class="btn btn-outline-primary"
						:href="brochures.memberRewards"
						target="_blank">
						{{ translate('member_rewards_videos_cta_2') }}
					</a>
				</div>
				<b-row>
					<b-col
						v-for="video in videos"
						:key="video.text"
						cols="12"
						lg="4">
						<div class="video-card d-flex flex-column justify-content-between align-items-center">
							<div style="position: relative;">
								<img
									:src="video.preview"
									style="width: 300px"
									alt="Video Preview">
								<div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(255, 255, 255, 0.8);" />
								<img
									v-b-modal="'member-rewards-modal-video'"
									:src="requireAsset('videos/play-icon.png')"
									style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 50px; cursor: pointer"
									alt="Play Icon"
									@click="currentVideoUrl = video.video">
							</div>
							<h6 v-html="video.text" />
						</div>
					</b-col>
				</b-row>
			</div>
		</section>
		<section class="steps-section">
			<div class="container d-flex flex-column align-items-center">
				<h5 v-html="translate('member_rewards_steps_title')" />
				<b-button
					variant="primary"
					class="mt-3"
					@click="becomeMember">
					{{ translate('member_rewards_join_now') }}
				</b-button>
			</div>
			<request-sponsor-modal
				v-model="showStoreModal"
				:redirect-type="redirectType" />
		</section>
	</div>
</template>

<script>
import WindowSizes from '@/mixins/WindowSizes';
import { MemberRewards as messages } from '@/translations';
import PublicStore from '@/mixins/PublicStore';
import RequestSponsorModal from '@/components/RequestSponsorModal/index.vue';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'MemberRewards',
	components: { RequestSponsorModal },
	mixins: [PublicStore, WindowSizes],
	messages: [messages],
	data() {
		return {
			currentVideoUrl: '',
		};
	},
	computed: {
		isMobile() {
			return ['xs', 'sm', 'md'].includes(this.windowWidth);
		},
		isDesktop() {
			return !this.isMobile;
		},
		videos() {
			return [
				{
					text: this.translate('member_rewards_video_1_title'),
					preview: this.requireAsset('videos/rewards.png'),
					video: 'https://player.vimeo.com/video/860354122?h=de12fdb718&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
				},
				{
					text: this.translate('member_rewards_video_2_title'),
					preview: this.requireAsset('videos/rewards-full-version.png'),
					video: 'https://player.vimeo.com/video/495513846?h=de12fdb718&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
				},
				{
					text: this.translate('member_rewards_video_3_title'),
					preview: this.requireAsset('videos/customer-program.png'),
					video: 'https://player.vimeo.com/video/695910399?h=de12fdb718&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
				},
			];
		},
		brochures() {
			return {
				memberRewards: 'https://s3-us-west-1.amazonaws.com/velovita/backoffice/media-library/en_3535_marketing_materials.pdf?v=14',
				memberRewardsChart: 'https://s3-us-west-1.amazonaws.com/velovita/backoffice/media-library/en_323836_marketing_materials.pdf?v=2',
			};
		},
		styles() {
			const styles = [
				`background: white url('${this.requireAsset('hero/hero-pattern.png')}') no-repeat center center / cover;`,
				this.isMobile ? 'height: max-content; padding-bottom: 2rem;' : '',
			];
			let padding = 'padding-top: 70px !important;';
			if (this.$replicated.replicatedSponsor()) {
				padding = this.windowWidth === 'xs' ? 'padding-top: 125px !important;' : 'padding-top: 70px !important;';
			}
			styles.push(padding);
			return styles.join(' ');
		},
	},
	methods: {
		requireAsset(directory) {
			// Allow importing assets with variable directories
			/* eslint-disable global-require */
			/* eslint-disable import/no-dynamic-require */
			// return require(`@/assets/images/themes/${process.env.VUE_APP_THEME}/landing_pages/member_rewards/${directory}`);
			return `${S3_PATH}/assets/landing-pages/${process.env.VUE_APP_THEME}/member_rewards/${directory}`;
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if ([].includes(vm.country)) {
				vm.$router.replace({ name: 'Store' });
			}
		});
	},
};
</script>

<style scoped>
.hero {
	height: 100vh;
	width: 100%;
	& .main-content {
		& h1 {
			font-family: MontserratExtraBold, Montserrat, sans-serif;
			text-transform: uppercase;
			font-size: 4rem;
			& > span {
				color: var(--primary);
			}
		}
		& p {
			font-size: 1.8rem;
			font-weight: 500;
			margin: 0;
		}
	}

	& .footer {
		& .opportunity {
			max-width: 500px;
			text-align: center;
			& h5 {
				font-family: MontserratExtraBold, Montserrat, sans-serif;
				text-transform: uppercase;
				font-size: 2rem;
				& > span {
					color: var(--primary);
				}
			}
			& p {
				font-weight: 500 !important;
				padding: 0 10px;
				margin: 0;
				& > span {
					color: var(--primary);
					font-weight: 600 !important;
				}
			}
		}
	}
}
.overview {
	padding: 50px 0 0 0;
	background-color: #fafafa;
	& h5 {
		font-family: MontserratExtraBold, Montserrat, sans-serif;
		text-transform: uppercase;
		font-size: 2rem;
		& > span {
			color: var(--primary);
		}
	}
	& p {
		font-weight: 500 !important;
		margin: 0;
		& b {
			font-weight: 600 !important;
			& > span {
				color: var(--primary);
			}
		}
	}
}
.video-section {
	padding: 50px 0;
	background-color: white;
	& h5 {
		font-family: MontserratExtraBold, Montserrat, sans-serif;
		text-transform: uppercase;
		font-size: 2rem;
		text-align: center;
		margin: 0;
		& > span {
			color: var(--primary);
		}
	}
	& .buttons {
		margin: 25px 0;
		& > a {
			font-family: MontserratExtraBold, Montserrat, sans-serif;
			padding: 10px 15px;
		}
	}
	& h6 {
		font-family: MontserratExtraBold, Montserrat, sans-serif;
		text-transform: uppercase;
		font-size: 1.1rem;
		text-align: center;
		width: 100%;
		& > span {
			color: var(--primary);
		}
	}
	& .video-card {
		& > div {
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		}
		& h6 {
			margin: 15px 0;
		}
	}
}
.steps-section {
	padding: 50px 0;
	background-color: #fafafa;
	& h5 {
		font-family: MontserratExtraBold, Montserrat, sans-serif;
		text-transform: uppercase;
		font-size: 2rem;
		text-align: center;
		margin: 0;
		& > span {
			color: var(--primary);
		}
	}
	& h6 {
		font-family: MontserratExtraBold, Montserrat, sans-serif;
		text-transform: uppercase;
		font-size: 1.5rem;
		text-align: center;
		width: 100%;
		& > span {
			color: var(--primary);
		}
	}
	& p {
		font-weight: 500 !important;
		margin: 0;
		& > span {
			color: var(--primary);
			font-weight: 600 !important;
		}
	}
	& button {
		font-family: MontserratExtraBold, Montserrat, sans-serif;
		padding: 10px 15px;
	}
}
</style>
